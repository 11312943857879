import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import { Container, Row, Col } from '@themesberg/react-bootstrap';

import { navigate } from "gatsby"
import Parse from '../vendor/Parse'
const { useEffect, } = React;
const Profile1 = "images/2021-01-30.jpg";

const iframeStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%'
}

const iframeContainerStyle = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '56.25%', /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

const IndexPage = ({ pageContext }) => {

  const { node } = pageContext;
  const [items, setItems] = React.useState([])
  const getAllEvents = () => {
    return Parse.Cloud.run('eventList')
  }

  useEffect(() => {
    getEvent()
  }, [])

  const getEvent = async () => {
    const items = await getAllEvents();
    setItems(items)
  }



  return <Layout>
    <Container  className="pb-2">
      <Row>
        <h2> { node.snippet.title } </h2>
        <div style={iframeContainerStyle}>
          <iframe style={iframeStyle} src={`https://www.youtube.com/embed/${node?.snippet?.resourceId?.videoId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

      </Row>
    </Container>
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
